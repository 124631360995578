import {Box, Button, Container, Typography} from "@mui/material";
import type {MetaFunction, LoaderFunctionArgs} from "@remix-run/cloudflare";
import {json} from "@remix-run/cloudflare";
import {Link, useSearchParams} from "@remix-run/react";
import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import CategoryCarousel from "~/components/CategoryCarousel";
import DtwImage from "~/components/DtwImage";
import {DtwSnackbar, SnackbarMessage} from "~/components/DtwSnackbar";
import Footer from "~/components/Footer";
import HomeProductSelection from "~/components/HomeProductSection";
import NavBar from "~/components/NavBar";
import TrustCarousel from "~/components/TrustCarousel";
import {RootSchema, useSettingsData} from "~/hooks";
import {useMainData} from "~/hooks";
import type {HomeJsonSchema, ProductJson} from "~/jsonSchemas";
import {getHomeFromSchema} from "~/schemas";
import {getHomeData, getProductsData} from "~/utils.server";

// load proper translations
// noinspection JSUnusedGlobalSymbols
export const handle = {i18n: "home"};

// noinspection JSUnusedGlobalSymbols
export async function loader({context, request}: LoaderFunctionArgs) {
    const env = context.cloudflare.env;
    const [home, products] = await Promise.all([
        getHomeData(request, env.DTW_SETTINGS_URL, env.DTW_BASE_URL),
        getProductsData(request, env.DTW_SETTINGS_URL, env.DTW_BASE_URL),
    ]);

    const schemas = {
        home: home as HomeJsonSchema,
        products: products as ProductJson[],
    };

    const obj = {
        home: getHomeFromSchema(schemas.home, schemas.products),
    };
    return json(obj);
}

// noinspection JSUnusedGlobalSymbols
export const meta: MetaFunction = ({matches}) => {
    const data = matches.find((m) => m.id === "root")?.data as RootSchema;
    const {main} = data;
    return [
        {title: main?.companyData.companyName ?? "DropTheWork"},
        {name: "description", content: main?.shortDescription},
        {tagName: "link", rel: "icon", href: main.squareLogo.default},
    ];
};

export default function Index() {
    const {banner, categories, cro} = useMainData();
    const {freeShippingMessageEnabled} = useSettingsData();
    const [snackbarMsg, setSnackbarMsg] = useState<SnackbarMessage>();
    const [searchParams] = useSearchParams();
    const {t} = useTranslation("home");

    const mainCategories = useMemo(
        () => categories.filter((c) => !c.parent),
        [categories]
    );

    useEffect(() => {
        const logout = searchParams.get("logout");
        if (logout === "1") {
            setSnackbarMsg({text: t("logoutSuccess"), variant: "default"});
        }
    }, [searchParams, setSnackbarMsg, t]);
    const closeSnack = () => setSnackbarMsg(undefined);

    return (
        <>
            <DtwSnackbar onClose={closeSnack} message={snackbarMsg}/>
            <NavBar/>
            <DtwImage
                sx={{
                    width: 1,
                    height: 384,
                }}
                picture={banner.picture}
                alt={banner.alt}
            />
            <Container
                fixed
                sx={(t) => ({
                    marginTop: {xs: t.spacing(4), md: t.spacing(4)},
                    marginBottom: {xs: t.spacing(4), md: t.spacing(4)},
                })}
            >
                {freeShippingMessageEnabled && !!cro.freeShippingMessage && <Typography sx={(t) => ({
                    marginBottom: {xs: t.spacing(4), md: t.spacing(4)},
                })}>{cro.freeShippingMessage}</Typography>}
                <TrustCarousel/>
                {!!mainCategories && mainCategories.length > 1 && (
                    <>
                        <Typography variant="h4" sx={{marginBottom: (t) => t.spacing(1)}}>
                            {t("categories")}
                        </Typography>
                        <CategoryCarousel categories={mainCategories}/>
                    </>
                )}
                <HomeProductSelection/>
                <Box sx={{display: "flex", justifyContent: "center", marginTop: t => t.spacing(4)}}>
                    <Link to="/products">
                        <Button variant="contained">{t('viewAll')}</Button>
                    </Link>
                </Box>
            </Container>
            <Footer/>
        </>
    );
}
